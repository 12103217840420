*, *:before, *:after {
  box-sizing: inherit;
}

* {
  margin: 0;
  padding: 0;
}

li + li {
  margin-top: 0 !important;
}