@font-face {
  font-family: 'Basier Circle';
  src: url('../assets/fonts/basiercircle-regular-webfont.woff2') format('woff2'),
    url('../assets/fonts/basiercircle-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Basier Circle';
  src: url('../assets/fonts/basiercircle-medium-webfont.woff2') format('woff2'),
       url('../assets/fonts/basiercircle-medium-webfont.woff') format('woff');
  font-weight: 500;
  font-style: normal;

}

@font-face {
  font-family: 'Basier Circle';
  src: url('../assets/fonts/basiercircle-semibold-webfont.woff2') format('woff2'),
    url('../assets/fonts/basiercircle-semibold-webfont.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Basier Circle';
  src: url('../assets/fonts/basiercircle-bold-webfont.woff2') format('woff2'),
    url('../assets/fonts/basiercircle-bold-webfont.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}